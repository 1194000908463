.accordionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f9fd;
  padding: 24px 43px 24px 24px;
  border-bottom: none;
}

.accordionWrapper {
  margin-bottom: 24px;
}

.accordionWrapper:last-child {
  margin-bottom: 0px;
}

.accordionActive {
  border-bottom: 1px solid var(--primary);
}

.title {
  color: var(--primary);
  font-size: 18px;
  line-height: 48px;
  margin-left: 40px;
}

.dFlex {
  display: flex;
}

.btnContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--secondary);
}

.btnActive {
  color: var(--primary);
}

.markContainer {
  width: 18px;
  height: 18px;
  margin-left: 19px;
  border-radius: 50%;
  border: 2px solid var(--secondary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.markContainer svg path {
  fill: var(--secondary);
}

.markBlue {
  border-color: var(--primary);
}

.markBlue svg path {
  fill: var(--primary);
}

.accordionContent {
  background-color: var(--white);
  padding: 32px 64px;
  box-shadow: 0px 16px 48px 0px #162a600f;
}

@media (max-width: 992px) {
  .accordionContainer {
    padding-right: 27px;
  }

  .accordionContent {
    padding: 24px;
  }

  .title,
  .btnContainer {
    font-size: 14px;
    line-height: 16px;
  }

  .dFlex {
    align-items: center;
  }
}

@media (max-width: 640px) {
  .btnContainer span {
    display: none;
  }
}
