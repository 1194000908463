.mainBtn {
  background-color: var(--secondary);
  border: 1px solid var(--secondary);
  color: var(--white);
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}

.mainBtn:hover {
  background-color: var(--primary);
}

@media (max-width: 992px) {
  .mainBtn {
    font-size: 14px;
    line-height: 16px;
    height: 48px;
  }
}
