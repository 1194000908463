.advantagesContainer h2 {
  color: var(--primary);
  text-align: center;
  padding-top: 88px;
}

.description {
  font-size: 24px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  color: var(--secondary);
}

.advantagesContainer {
  background-image: url("../../assets/images/advantages-desktop.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 733px;
  width: 100%;
}

.advantagesContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 296px;
}

.leftBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.leftBlock .propositionWidth,
.rightBlock .propositionWidth {
  margin-top: 56px;
}

.rightBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.propositionContainer {
  max-width: 352px;
  min-width: 288px;
  width: 100%;
  height: 152px;
  box-shadow: 0px 24px 48px 0px #15203e40;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  padding: 0px 26.5px;
}

.propositionContainer p {
  color: var(--primary);
  text-align: center;
  margin-top: 16px;
}

.propositionWidth {
  max-width: 288px;
}

.sliderProposition {
  margin-top: 385px;
}

.contentCenter {
  display: flex !important;
  justify-content: center;
}

.sliderPropositionContainer {
  box-shadow: 0px 16px 24px 0px #15203e40;
}

.gradientWrapper {
  background: linear-gradient(0deg, #f3f5fa 0%, rgba(243, 245, 250, 0) 89.66%);
}

/* Privileges Section */
.privilegesContainer {
  margin-top: 96px;
  padding: 0px 24px;
}

.privilegesContainer h2 {
  text-align: center;
  color: var(--primary);
}

.privilegesContainer h2 span {
  color: var(--secondary);
}

.privilegesContent {
  display: flex;
  margin: 56px 0px;
}

.privilegesItem {
  max-width: 372.67px;
  width: 100%;
  text-align: center;
  border-bottom: 8px solid var(--secondary);
}

.privilegesItem:nth-child(2) {
  border-color: var(--primary);
}

.privilegesTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--primary);
  margin-top: 24px;
}

.privilegesDescription {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--dark);
  margin-bottom: 24px;
}

.completelyRemote {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  color: var(--light);
  margin-bottom: 56px;
  text-align: center;
}

.completelyRemote span {
  color: var(--secondary);
}

.likeContainer {
  height: 24px;
}

.likeContainer svg {
  margin-right: 16px;
}

.likeContainer svg path {
  fill: var(--secondary);
}

button.privilegesBtn {
  background-color: var(--primary);
  border-color: var(--primary);
  margin-bottom: 120px;
}

button.privilegesBtn:hover {
  background-color: var(--secondary);
}

button.privilegesBtn:hover svg path {
  fill: var(--white);
}
/* End OfPrivileges Section */

/* Cloud Section */
.cloudContainer {
  padding: 0px 24px 312px 24px;
}

.cloudContent {
  display: flex;
  justify-content: space-between;
}

.cloudLeft {
  max-width: 545px;
  width: 100%;
}

.cloudLeft svg {
  margin: 0px 64px;
}

.cloudLeft h2 {
  text-align: center;
  color: var(--primary);
  margin: 64px 0px 32px 0px;
}

.cloudLeft h2 span {
  color: var(--secondary);
}

.cloudLeft p {
  color: var(--dark);
  text-align: center;
}
.cloudRight {
  line-height: 0;
}
/* End Of Cloud Section */

/* Opinions Section */
.opinionsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.opinionItem {
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin-bottom: 16px;
  width: 100%;
  color: var(--white);
}

.opinionItem svg {
  margin-right: 16px;
}

.opinionItem:nth-child(1) {
  max-width: 374px;
  background-color: #82a2c5;
}

.opinionItem:nth-child(2) {
  max-width: 404px;
  background-color: #adbfd6;
}

.opinionItem:nth-child(3) {
  max-width: 448px;
  background-color: #d6dfea;
}

.opinionItem:nth-child(3) svg {
  margin-left: 10px;
}

/* End Of Opinions Section */

/* Get Started In Section */
.getStartedContainer {
  width: 100%;
  margin-top: 164px;
  background: linear-gradient(0deg, #f3f5fa 0%, rgba(243, 245, 250, 0) 89.66%);
  padding-bottom: 120px;
}

.getStartedContainer button {
  margin-bottom: 0px;
}

.getStartedContainer h2 {
  font-size: 32px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--primary);
}

.getStartedContent {
  display: flex;
  justify-content: space-between;
  margin: 64px 0px 88px 0px;
}

.getStartedDesc {
  text-align: center;
  margin-top: 64px;
}

.getStartedContainer .longArrow:last-child {
  display: none;
}

.cloudWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cloudImg {
  background-image: url("../../assets/images/cloud.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 288px;
  height: 168px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cloudImg svg {
  margin: 64px 0px 8px 0px;
}

.cloudImg p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  color: var(--primary);
}

.longArrow {
  margin: 84px 16px 0px 16px;
}
/* End Of Get Started In Section */

@media (max-width: 1120px) {
  .getStartedContainer {
    margin-top: 64px;
    background: transparent;
    padding: 0px 20px 24px 20px;
  }

  .getStartedContent {
    flex-direction: column;
    margin: 40px 0px 24px 0px;
  }

  .getStartedDesc {
    margin-top: 40px;
    max-width: 321px;
  }

  .longArrow {
    transform: rotate(90deg);
    width: 84px;
    display: block;
    margin: 48px auto;
  }

  .privilegesContainer {
    margin-top: 0px;
  }
}

@media (max-width: 1050px) {
  .cloudContainer {
    padding-bottom: 216px;
  }

  .cloudRight {
    display: none;
  }

  .cloudContent {
    justify-content: center;
  }

  .cloudLeft svg {
    max-width: 236px;
    height: 139px;
    display: block;
    margin: 0px auto;
  }
}

@media (max-width: 850px) {
  .privilegesContainer h2,
  .cloudLeft h2 {
    font-size: 22px;
    line-height: 32px;
  }

  .privilegesContent {
    flex-direction: column;
    align-items: center;
    margin: 36px 0px;
  }

  .privilegesItem {
    max-width: unset;
    margin-bottom: 36px;
  }

  .privilegesItem:last-child {
    margin-bottom: 0px;
  }

  .privilegesItem svg {
    height: 48px;
  }

  .privilegesTitle,
  .privilegesDescription {
    font-size: 16px;
    line-height: 24px;
  }

  .completelyRemote {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 36px;
  }

  button.privilegesBtn {
    margin-bottom: 40px;
  }

  .cloudLeft h2 {
    margin: 39px 0px 24px 0px;
  }

  .cloudLeft p {
    font-size: 14px;
    line-height: 24px;
  }
}

@media (max-width: 450px) {
  .advantagesContainer {
    background-image: url("../../assets/images/advantages-mobile.png");
    height: 372px;
    background-size: contain;
  }

  .advantagesContainer h2 {
    padding-top: 48px;
    font-size: 22px;
    line-height: 32px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
  }

  .propositionContainer {
    min-width: 320px;
    max-width: 320px;
    height: 144px;
  }

  .sliderProposition {
    margin-top: 240px;
  }

  .sliderPropositionContainer svg {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 360px) {
  .sliderProposition {
    margin-top: calc(100% - 145px);
  }
}

@media (min-width: 320px) and (max-width: 450px) {
  .getStartedContainer {
    margin-top: 180px;
  }
}

@media (min-width: 451px) and (max-width: 1120px) {
  .getStartedContainer {
    margin-top: 80px;
  }
}

@media (max-width: 747px) {
  .opinionsContainer {
    align-items: flex-start;
  }

  .opinionItem {
    height: 54px;
  }

  .opinionItem p {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    width: 186px;
  }

  .opinionItem svg {
    width: 16px;
    height: 16px;
  }

  .opinionItem:nth-child(3) svg {
    margin-left: 0px;
  }
}
