.checkboxContent label {
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 16px;
  line-height: 24px;
}

.checkboxContent svg {
  margin-right: 16px;
}

.pRelative {
  position: relative;
  height: 16px;
}

.inputUncheck {
  left: 0px;
  position: absolute;
  top: 2px;
  width: 16px;
  height: 16px;
  z-index: -1;
}
