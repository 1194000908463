@font-face {
  font-family: "Formular";
  src: url("../assets/fonts/Formular-Light.eot");
  src: local("Formular Light"), local("Formular-Light"),
    url("../assets/fonts/Formular-Light.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Formular-Light.woff2") format("woff2"),
    url("../assets/fonts/Formular-Light.woff") format("woff"),
    url("../assets/fonts/Formular-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Formular";
  src: url("../assets/fonts/Formular.eot");
  src: local("Formular"),
    url("../assets/fonts/Formular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Formular.woff2") format("woff2"),
    url("../assets/fonts/Formular.woff") format("woff"),
    url("../assets/fonts/Formular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Formular";
  src: url("../assets/fonts/Formular-Medium.eot");
  src: local("Formular Medium"), local("Formular-Medium"),
    url("../assets/fonts/Formular-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Formular-Medium.woff2") format("woff2"),
    url("../assets/fonts/Formular-Medium.woff") format("woff"),
    url("../assets/fonts/Formular-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Formular";
  src: url("../assets/fonts/Formular-Bold.eot");
  src: local("Formular Bold"), local("Formular-Bold"),
    url("../assets/fonts/Formular-Bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/Formular-Bold.woff2") format("woff2"),
    url("../assets/fonts/Formular-Bold.woff") format("woff"),
    url("../assets/fonts/Formular-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-Light.eot");
  src: url("../assets/fonts/Manrope-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Manrope-Light.woff2") format("woff2"),
    url("../assets/fonts/Manrope-Light.woff") format("woff"),
    url("../assets/fonts/Manrope-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/Manrope-SemiBold.eot");
  src: url("../assets/fonts/Manrope-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Manrope-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Manrope-SemiBold.woff") format("woff"),
    url("../assets/fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
